import React from "react";
import "./Map.css";

const Map = () => {
  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width="378"
          height="280"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Beechavenue%2054-62,%201119%20PW%20Schiphol-Rijk,%20Netherlands&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        />
        <a href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon/">
          divi discount
        </a>
        <br />
        <a href="https://www.embedgooglemap.net">embed google map wordpress</a>
      </div>
    </div>
  );
};

export default Map;
