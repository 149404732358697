import React, { useState } from "react";
import Layout from "../components/layout";
import Container from "../components/common/Container";
import CommunitySection from "../components/common/CommunitySection";
import copyIcon from "../images/icons/Duplicate.svg";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Map from "../components/contact-us/Map";
import SEO from "../components/common/Seo";
import MailIcon from "./../components/icons/MailIcon";

const ContactUs = () => {
  const [email, setEmail] = useState("info@fetchai.foundation");
  const [isCopied, copyToClipboard] = useCopyToClipboard();

  return (
    <Layout>
      <Container classes="text-center mb-20 !px-4 md:!px-[34px]">
        <h1 className="text-[40px] leading-[48px] font-semibold text-night-rider md:text-[56px] md:leading-[67px] font-ubuntu uppercase flex items-center flex-row justify-center mt-6 md:mt-5 mb-4">
          <span className="inline-block textHeadingGradient ">
            Contact&nbsp;
          </span>
          <span className="text-[42px] md:text-[56px] inline-block text-night-rider">
            Us
          </span>
        </h1>

        <div className="font-ubuntu text-[20px] leading-5 md:text-2xl font-normal flex items-center space-x-2 justify-center mt-[75px] mb-[72px] md:my-20 flex-col md:flex-row">
          <MailIcon width={36} />
          <span className="flex space-x-2 min-h-[35.02px]">
            <a href={`mailto:${email}`} className="underline text-gradient-one">
              Send us an email
            </a>
            <span>at</span>
          </span>
          <span className="flex space-x-2">
            <span className="text-gradient-one">{email}</span>
            <img
              src={copyIcon}
              alt="copy"
              className="cursor-pointer w-[20px] md:w-[36px]"
              onClick={() => copyToClipboard(email)}
              id="copy"
              data-tooltip-content={isCopied ? "Copied" : "Copy to clipboard"}
            />
          </span>
        </div>

        <div className="mt-10 md:mt-[107px] mb-16">
          <p className="mb-3 text-sm card_title md:mb-4">WHERE TO FIND US</p>
          <div className="min-h-[280px] flex items-center justify-center max-w-[620px] mx-auto flex-col md:flex-row">
            <div>
              <Map />
            </div>
            <div className="w-full h-full text-center md:text-left mt-5 md:mt-0 ml-8 font-ubuntu font-normal text-base md:text-base text-night-rider leading-[26px]">
              <p>Beech Avenue 54-62</p>
              <p>Schiphol</p>
              <p>1119 PW</p>
              <p>Netherlands</p>
            </div>
          </div>
        </div>
      </Container>
      {/* <div className="-mt-8 md:mt-[104px]">
        <CommunitySection
          classes="mt-[90px] px-0 md:!mb-20"
          titleSectionClasses="px-6"
          cardSectionClasses="px-0 !mx-0 md:!mx-0"
          buttonSectionClasses="ml-6 md:ml-24"
        />
      </div> */}
      <Tooltip anchorId="copy" content="" />
    </Layout>
  );
};

export default ContactUs;

export const Head = () => <SEO title="Contact Us" />;
