import React from "react";

const MailIcon = ({ width = 37, height = 36, ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2584_6614)">
        <path
          d="M5 12L16.8359 19.8906C17.8436 20.5624 19.1564 20.5624 20.1641 19.8906L32 12M8 28.5H29C30.6569 28.5 32 27.1569 32 25.5V10.5C32 8.84315 30.6569 7.5 29 7.5H8C6.34315 7.5 5 8.84315 5 10.5V25.5C5 27.1569 6.34315 28.5 8 28.5Z"
          stroke="url(#paint0_linear_2584_6614)"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2584_6614"
          x="3.5"
          y="6"
          width="30"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.490196 0 0 0 0 0.819608 0 0 0 0 0.807843 0 0 0 0.75 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2584_6614"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2584_6614"
          x1="32"
          y1="18"
          x2="5"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6563CC" />
          <stop offset="1" stopColor="#346FCC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MailIcon;
