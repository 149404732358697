import React, { useEffect, useState } from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

const SEO = ({ title, description, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const [pathName, setPathName] = useState("");

  useEffect(() => {
    if (window) {
      setPathName(window?.location?.pathname);
    }
  }, []);

  const seo = {
    title: `${title} | ${defaultTitle}`,
    description: description || defaultDescription,
    image: `${siteUrl}/${image}`,
    url: `${siteUrl}${pathName || ``}`,
    twitterUsername,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:title" content={title}></meta>
      <meta property="og:site_name" content={seo.description}></meta>
      <meta name="og:image" content={seo.image} />
      <meta name="og:type" content="website" />
      <meta name="fb:app_id" content="null" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      {children}
    </>
  );
};
export default SEO;
